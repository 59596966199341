import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';

function ContactForm() {
  const [hasBeenSubmitted, toggleHasBeenSubmitted] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query ContactFormQuery {
          site {
            siteMetadata {
              contact_post_url
            }
          }
        }
      `}
      render={data => (
        <div>
          <Formik
            initialValues={{ name: '', email: '', phone: '', message: '' }}
            validate={values => {
              let errors = {};

              if (!values.name) {
                errors.name = 'Eingabe wird benötigt';
              } else if (values.name.length < 2) {
                errors.name =
                  'Es müssen mindestens 2 Zeichen eingegeben werden';
              } else if (values.name.length > 64) {
                errors.name =
                  'Es dürfen höchstens 64 Zeichen eingegeben werden';
              }

              if (!values.email) {
                errors.email = 'Eingabe wird benötigt';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Ungültige E-Mail Adresse';
              } else if (values.email.length > 255) {
                errors.location =
                  'Es dürfen höchstens 255 Zeichen eingegeben werden';
              }

              if (values.phone) {
                if (values.phone.length < 9) {
                  errors.phone =
                    'Es müssen mindestens 9 Zeichen eingegeben werden';
                } else if (values.phone.length > 32) {
                  errors.phone =
                    'Es dürfen höchstens 32 Zeichen eingegeben werden';
                }
              }

              if (!values.message) {
                errors.message = 'Eingabe wird benötigt';
              } else if (values.message.length > 4096) {
                errors.phone =
                  'Es dürfen höchstens 4096 Zeichen eingegeben werden';
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                axios
                  .post(data.site.siteMetadata.contact_post_url, values)
                  .then(function(response) {
                    // Track Matomo Goal, if Matomo loaded (ie without do not track)
                    if (window._paq) {
                      window._paq.push(['trackGoal', 2]);
                    }
                    toggleHasBeenSubmitted(!hasBeenSubmitted);
                  })
                  .catch(function(error) {
                    alert(
                      'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut, oder kontaktieren Sie uns per E-Mail.'
                    );
                  });
                setSubmitting(false);
              }, 400);
            }}
          >
            {function({ isSubmitting, errors, touched }) {
              if (hasBeenSubmitted) {
                return (
                  <p>
                    Ihre Nachricht wurde versendet, vielen Dank. Wir melden uns
                    so bald wie möglich bei Ihnen
                  </p>
                );
              } else {
                return (
                  <Form className="w-full">
                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-name"
                        >
                          Ihr Name
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <Field
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            errors.name && touched.name ? 'border-red-500' : ''
                          }`}
                          type="text"
                          name="name"
                          id="form-name"
                        />
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="name"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-email"
                        >
                          Ihre E-Mail
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <Field
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            errors.email && touched.email ? 'border-red-500' : ''
                          }`}
                          type="email"
                          name="email"
                          id="form-email"
                        />
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="email"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-phone"
                        >
                          Ihre Telefonnummer (optional)
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <Field
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            errors.phone && touched.phone ? 'border-red-500' : ''
                          }`}
                          type="text"
                          name="phone"
                          id="form-phone"
                        />
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="phone"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-message"
                        >
                          Ihre Nachricht
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <Field
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            errors.message && touched.message ? 'border-red-500' : ''
                          }`}
                          as="textarea"
                          name="message"
                          id="form-message"
                        />
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="message"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center">
                      <div className="md:w-1/3"></div>
                      <div className="md:w-2/3">
                        <button
                          className="hover:underline gradient text-white font-bold rounded-full py-2 px-8 shadow-lg"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Senden
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }
            }}
          </Formik>
        </div>
      )}
    />
  );
}

export default ContactForm;
