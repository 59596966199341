import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import FooterWaves from '../components/footer-waves';
import ContactForm from '../components/contact-form';

function Kontakt() {
  function handleEmailClick() {
    // Track Matomo Goal, if Matomo loaded (ie without do not track)
    if (window._paq) {
      window._paq.push(['trackGoal', 3]);
    }
  }

  return (
    <Layout>
      <SEO
        title="Kontaktieren Sie uns"
        description="Wir freuen uns auf Ihre Kontaktanfrage. Ob für eine Bestellung oder für den Support nach der Fertigstellung. Wir sind für Sie da."
      />
      <section className="bg-white py-8 mt-10 p-6">
        <div className="container max-w-5xl mx-auto m-8 text-gray-800">
          <h1 className="text-3xl md:text-4xl">Kontakt</h1>
          <div className="container max-w-5xl mx-auto mt-2 mb-8">
            <div className="flex flex-wrap">
              <div className="w-5/6 sm:w-1/3">
                <p>
                  Für Fragen zu unserer Shoplösung erreichen Sie uns unter den
                  folgenden Kontaktmöglichkeiten, oder auch über das
                  Kontaktformular.
                </p>

                <p>
                  E-Mail:{' '}
                  <a
                    href="mailto:hello@sofortshop.ch"
                    className="text-blue-500 hover:underline"
                    onClick={handleEmailClick}
                  >
                    hello@sofortshop.ch
                  </a>
                </p>

                <p>
                  Sie möchten bestellen? Hier geht's zum{' '}
                  <Link
                    to="/bestellformular/"
                    className="text-blue-500 hover:underline"
                  >
                    Bestellformular
                  </Link>
                  .
                </p>
              </div>
              <div className="w-full sm:w-2/3 px-6">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterWaves />
    </Layout>
  );
}

export default Kontakt;
